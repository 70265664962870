import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { MdPermIdentity, MdMenu, MdClose } from "react-icons/md";
import { GoCheck } from "react-icons/go";

import Connect from "../../store/config/connect";
import { LogOutUser } from "../../store/auth";

import MenuNaoLogado from "../MenuNaoLogado/MenuNaoLogado";
import MenuLogado from "../MenuLogado/MenuLogado";
import MenuUsuario from "../MenuUsuario/MenuUsuario";

import ModalDeleteProfile from "../EditarMeuPerfil/ModalDeleteProfile/ModalDeleteProfile";

import "./Header.css";

const Header = (props) => {
  // console.log("alowww", props);
  let history = useHistory();

  const [openLeftMenu, setOpenLeftMenu] = useState(false);
  function handleLeftMenu(status) {
    setOpenLeftMenu(status);
  }

  const [openRightMenu, setOpenRightMenu] = useState(false);
  function handleRightMenu(status) {
    setOpenRightMenu(status);
  }

  const [isMobile, setIsMobile] = useState(props.helper.isMobile);
  useEffect(() => {
    setIsMobile(props.helper.isMobile);
  }, [props.helper.isMobile]);

  const [isLogged, setIsLogged] = useState(false);
  const [firstLetterUsername, setFirstLetterUsername] = useState("");
  useEffect(() => {
    let firstLetterUsername = props.auth.isLogged
      ? props.auth.user.name.charAt(0)
      : "";
    setIsLogged(props.auth.isLogged);
    setFirstLetterUsername(firstLetterUsername);
  }, [props.auth]);

  function goToHome() {
    history.push("/");
  }

  function handleLogout() {
    props.dispatch(LogOutUser());
    goToHome();
  }

  const [modalClosed, setModalClosed] = useState(true);

  if (isMobile) {
    return (
      <div className="main-container">
        { props.auth && props.auth.user &&
          <ModalDeleteProfile
          setModalClosed={setModalClosed}
          modalClosed={modalClosed}
          userId={props.auth.user.id}
          token={props.auth.user.token}
          />
        }
        <div
          id="overlay"
          style={{ display: openLeftMenu || openRightMenu ? "block" : "none" }}
          onClick={() => {
            handleLeftMenu(false);
            handleRightMenu(false);
          }}
        >
          <div>
            <span id="closeLeft">
              <MdClose style={{ display: openRightMenu ? "block" : "none" }} />
            </span>
            <span id="closeRight">
              <MdClose style={{ display: openLeftMenu ? "block" : "none" }} />
            </span>
          </div>
        </div>
        <div className={openLeftMenu ? "menu-left-open" : "menu-left"}>
          {isLogged ? <MenuLogado /> : <MenuNaoLogado />}
        </div>
        <div className={openRightMenu ? "menu-right-open" : "menu-right"}>
          {isLogged ? (
            <MenuUsuario
              handleClose={handleRightMenu}
              status={openRightMenu}
              setModalClosed={setModalClosed}
            />
          ) : (
            <div></div>
          )}
        </div>
        <div className="header">
          <div className="container">
          
            {/* <div className="containerWave">
              <div>
                <span className="msdWave"></span>
              </div>
            </div> */}
            {isLogged ? (
              <div
                className="menuUtilities"
                onClick={() => {
                  handleLeftMenu(!openLeftMenu);
                }}
              >
                <MdMenu />
              </div>
            ) : (
              ""
            )}
            <div
              className="logo"
              onClick={() => {
                goToHome();
              }}
            >
              <span className="bravectoLogo"></span>
            </div>
            {props.hideLoginButton ? undefined : (
              <div className="menuUser">
                {!isLogged ? (
                <>
                  <Link to="/login">
                    <MdPermIdentity />
                  </Link>
                  <div id="msd-wave">
                    <a href="https://www.msd-saude-animal.com.br/" target="_blank" rel="noopener noreferrer"><span></span></a>
                  </div>
                </>
                ) : (
                  <div
                    id="container__round"
                    onClick={() => {
                      handleRightMenu(!openRightMenu);
                    }}
                  >
                    <div id="round-div-small">
                      <GoCheck />
                    </div>
                    <div id="round-div">
                      <span>{firstLetterUsername}</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    if (isLogged) {
      return (
        <div className="main-container-logged">
          <div id="row">
            <div id="logo">
              <NavLink to="/clube-de-vantagens">
                <span></span>
              </NavLink>
            </div>
            <div id="menu">
              <div id="upper-content">
                <div id="editar-meu-perfil">
                  <span id="icon"></span>
                  <NavLink
                    to="/editar-meu-perfil"
                    activeClassName="activeUpperLink"
                  >
                    MEU PERFIL
                  </NavLink>
                </div>
                <div id="meus-pets">
                  <span id="icon"></span>
                  <NavLink to="/meus-pets" activeClassName="activeUpperLink">
                    MEUS PETS
                  </NavLink>
                </div>
                <div id="minhas-compras">
                  <span id="icon"></span>
                  <NavLink
                    to="/minhas-compras"
                    activeClassName="activeUpperLink"
                  >
                    MINHAS COMPRAS
                  </NavLink>
                </div>
                <div id="duvidas">
                  <span id="icon"></span>
                  <NavLink to="/duvidas" activeClassName="activeUpperLink">
                    DÚVIDAS
                  </NavLink>
                </div>

                <div
                  id="logoff"
                  onClick={() => {
                    handleLogout();
                  }}
                >
                  <MdClose id="icon" />
                  <NavLink to="/">SAIR</NavLink>
                </div>
              </div>
              <div id="lower-content">

                <div>
                  <NavLink to="/garantia-bravecto" activeClassName="activeLink">
                    GARANTIA BRAVECTO
                  </NavLink>
                </div>

                <div>
                  <NavLink to="/meus-beneficios" activeClassName="activeLink">
                    MEUS BENEFÍCIOS
                  </NavLink>
                </div>

                {/*
                                    <div>
                                        <NavLink to="/onde-comprar" activeClassName="activeLink">
                                            ONDE COMPRAR
                                        </NavLink>
                                    </div>
                                    */}

                {/* <div>
                                    <NavLink to="/faq" activeClassName="activeLink">
                                        FAQ
                                    </NavLink>
                                </div>
                                <div>
                                    <NavLink to="/quem-somos" activeClassName="activeLink">
                                        QUEM SOMOS
                                    </NavLink>
                                </div> */}
                <div>
                  <NavLink
                    to="/cadastrar-lembrete"
                    activeClassName="activeLink"
                  >
                    CADASTRAR LEMBRETE
                  </NavLink>
                </div>
                <div>
                  <NavLink to="/cadastrar-nota" activeClassName="activeLink">
                    CADASTRAR NOTA FISCAL
                  </NavLink>
                </div>

                <div>
                  <NavLink to="/blog" activeClassName="activeLink">
                    BLOG
                  </NavLink>
                </div>

                {/*
                                    <div>
                                        <NavLink to="/desconto-em-bravecto" activeClassName="activeLink">
                                            DESCONTO EM BRAVECTO
                                        </NavLink>
                                    </div>
                                    */}
              </div>
            </div>
            <div id="msd-wave">
              <a href="https://www.msd-saude-animal.com.br/" target="_blank" rel="noopener noreferrer"><span></span></a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-container">
          <div id="row">
            <div id="half">
              <div
                id="logo"
                onClick={() => {
                  goToHome();
                }}
              >
                <span></span>
              </div>
            </div>
            <div id="half">
              <div id="option">
                {/*
                                        <Link to="/faq">
                                            <span>FAQ</span>
                                        </Link>
                                        */}
              </div>

              <div id="option">
                {/*
                                        <Link to="/quem-somos">
                                            <span>QUEM SOMOS</span>
                                        </Link>
                                        */}
              </div>

              <div id="option">
                {/*
                                        <Link to="/blog">
                                            <span>BLOG</span>
                                        </Link>
                                        */}
              </div>
              {props.hideLoginButton ? undefined : (
                <div id="button">
                  <Link to="/login">
                    <button type="button" id="btn-cadastrar-header">
                      <MdPermIdentity />
                      Login
                    </button>
                  </Link>
                </div>
              )}
              <div id="msd-wave">
                <a href="https://www.msd-saude-animal.com.br/" target="_blank" rel="noopener noreferrer"><span></span></a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
};

const mapStateToProps = ({ auth, helper }, { hideLoginButton }) => {
  return {
    auth,
    helper,
    hideLoginButton,
  };
};

export default Connect(mapStateToProps)(Header);
